
export default {
  props: {
    placement: {
      type: [String, Array],
      default: 'bottom-right',
    },
    blockSize: {
      type: Number,
      default: 4, // rems
    },
    blocks: {
      type: Number,
      default: 1,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    blockSizeComputed() {
      return this.$screen.md ? this.blockSize : (this.$screen.sm ? Math.round(this.blockSize / 2.5) : Math.round(this.blockSize / 3));
    },
    stylesVertical() {
      return `width: ${this.blockSizeComputed * this.blocks}rem; height: ${this.blockSizeComputed}rem;`;
    },
    stylesHorizontal() {
      return `width: ${this.blockSizeComputed}rem; height: ${this.blockSizeComputed * this.blocks}rem;`;
    },
  },
};

import { render, staticRenderFns } from "./HomeHero.vue?vue&type=template&id=65c032c4&scoped=true&"
import script from "./HomeHero.vue?vue&type=script&lang=js&"
export * from "./HomeHero.vue?vue&type=script&lang=js&"
import style0 from "./HomeHero.vue?vue&type=style&index=0&id=65c032c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c032c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/frontend/components/ui/Button.vue').default,HomeGlobe: require('/vercel/path0/frontend/components/home/HomeGlobe.vue').default})

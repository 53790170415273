
export default {
  data() {
    return {
      articles: [],
    };
  },

  async mounted() {
    const { results } = await this.$axios.$get('/public/articles', {
      params: {
        limit: 3,
      },
    }).catch(() => ([]));

    this.articles = results;
  },
};

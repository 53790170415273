
// import _padStart from 'lodash/padStart';

export default {
  props: {
    fund: {
      type: Object,
      required: true,
    },
  },

  // methods: {
  //   _padStart,
  // },
};

import { render, staticRenderFns } from "./index.vue?vue&type=template&id=759c22fc&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/vercel/path0/frontend/components/home/HomeHero.vue').default,HomeArticles: require('/vercel/path0/frontend/components/home/HomeArticles.vue').default,StartupsValues: require('/vercel/path0/frontend/components/founders/StartupsValues.vue').default,HomeMilestones: require('/vercel/path0/frontend/components/home/HomeMilestones.vue').default,InvestorsPortfolio: require('/vercel/path0/frontend/components/investors/InvestorsPortfolio.vue').default,HomeFunds: require('/vercel/path0/frontend/components/home/HomeFunds.vue').default})

import { render, staticRenderFns } from "./ArticlesArticleItem.vue?vue&type=template&id=c8a1d942&"
import script from "./ArticlesArticleItem.vue?vue&type=script&lang=js&"
export * from "./ArticlesArticleItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageDecoration: require('/vercel/path0/frontend/components/ui/ImageDecoration.vue').default})

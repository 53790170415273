
export default {
  computed: {
    isDark() {
      return this.$store.state.ui.isDarkMode;
    },
  },

  watch: {
    isDark: {
      handler() {
        this.loadGlobe();
      },
    },

    '$screen.breakpoint'() {
      this.loadGlobe();
    },
  },

  async mounted() {
    await this.$nextTick();
    this.loadGlobe();
  },

  methods: {
    loadGlobe() {
      if (window.Globe) {
        this.initGlobe();
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = '//unpkg.com/globe.gl@2.26.2/dist/globe.gl.min.js';
        script.onload = () => window.Globe && this.initGlobe();
        document.body.appendChild(script);
      }
    },
    async initGlobe() {
      await this.$nextTick();
      const MAP_AIRPORTS = [
        { code: 'ATH', city: 'Athens', lat: 37.9356, lng: 23.9484 },
        { code: 'AMS', city: 'Amsterdam', lat: 52.3105, lng: 4.7683 },
        { code: 'CDG', city: 'Paris', lat: 48.9079, lng: 2.4647 },
        { code: 'LHR', city: 'London', lat: 51.47, lng: -0.4543 },
        { code: 'MAD', city: 'Madrid', lat: 40.4983, lng: -3.5676 },
        { code: 'BCN', city: 'Barcelona', lat: 41.2974, lng: 2.0833 },
        { code: 'OSL', city: 'Oslo', lat: 60.1976, lng: 11.1004 },
        { code: 'FRA', city: 'Frankfurt', lat: 50.0379, lng: 8.5622 },
        { code: 'VIE', city: 'Vienna', lat: 48.1126, lng: 16.5755 },
        { code: 'BRU', city: 'Brussels', lat: 50.901, lng: 4.4856 },
        { code: 'ZRH', city: 'Zurich', lat: 47.4612, lng: 8.5535 },
        { code: 'GVA', city: 'Geneva', lat: 46.237, lng: 6.1091 },
        { code: 'SIN', city: 'Singapore', lat: 1.3644, lng: 103.9915 },
        { code: 'DOH', city: 'Doha', lat: 25.2609, lng: 51.6138 },
        { code: 'DFW', city: 'Dallas', lat: 32.8998, lng: -97.0403 },
        { code: 'LAX', city: 'Los Angeles', lat: 33.9416, lng: -118.4085 },
        { code: 'MIA', city: 'Miami', lat: 25.7959, lng: -80.2871 },
        { code: 'ORD', city: 'Chicago', lat: 41.9803, lng: -87.909 },
        { code: 'JFK', city: 'New York', lat: 40.6413, lng: -73.7781 },
        { code: 'EWR', city: 'Newark', lat: 40.6895, lng: -74.1745 },
        { code: 'SFO', city: 'San Francisco', lat: 37.6213, lng: -122.379 },
        { code: 'DXB', city: 'Dubai', lat: 25.2532, lng: 55.3657 },
        { code: 'AUH', city: 'Abu Dhabi', lat: 24.4419, lng: 54.6501 },
        { code: 'SYD', city: 'Sydney', lat: -33.95, lng: 151.1819 },
        { code: 'MEL', city: 'Melbourne', lat: -37.6709, lng: 144.8433 },
        { code: 'PER', city: 'Perth', lat: -31.9385, lng: 115.9672 },
        { code: 'DRW', city: 'Darwin', lat: -12.4154, lng: 130.8829 },
        { code: 'MEX', city: 'Mexico City', lat: 19.4361, lng: -99.0719 },
        { code: 'SCL', city: 'Santiago de Chile', lat: -33.3898, lng: -70.7944 },
        { code: 'BUE', city: 'Buenos Aires', lat: -34.815, lng: -58.5348 },
        { code: 'RIO', city: 'Rio de Janeiro', lat: -22.8053, lng: -43.2566 },
        { code: 'SAO', city: 'Sao Paulo', lat: -23.4306, lng: -46.473 },
        { code: 'LIM', city: 'Lima', lat: -12.0241, lng: -77.112 },
        { code: 'UIO', city: 'Quito', lat: -0.1242, lng: -78.3606 },
        { code: 'BOG', city: 'Bogota', lat: 4.701, lng: -74.1461 },
        { code: 'MUC', city: 'Munich', lat: 48.351, lng: 11.7764 },
        { code: 'HEL', city: 'Helsinki', lat: 60.3183, lng: 24.9497 },
        { code: 'IAH', city: 'Houston', lat: 29.9902, lng: -95.3368 },
        { code: 'YUL', city: 'Montreal', lat: 45.4657, lng: -73.7455 },
        { code: 'YTO', city: 'Toronto', lat: 43.6777, lng: -79.6248 },
        { code: 'YVR', city: 'Vancouver', lat: 49.1967, lng: -123.1815 },
        { code: 'YYC', city: 'Calgary', lat: 51.1215, lng: -114.0076 },
        { code: 'HNL', city: 'Honolulu', lat: 21.3186, lng: -157.9253 },
        { code: 'PTY', city: 'Panama City', lat: 9.0669, lng: -79.3872 },
      ];

      const lineColor = this.isDark ? '#555555' : '#9D9D9D';
      const lineColorPrimary = '#8BCF2B';
      const globeSize = this.$screen['3xl'] ? 700 : (this.$screen.xl ? 600 : 500);

      const arcsData = MAP_AIRPORTS.map(({ lat, lng }, index) => ({
        startLat: lat,
        startLng: lng,
        endLat: this.getNext(MAP_AIRPORTS, index).lat,
        endLng: this.getNext(MAP_AIRPORTS, index).lng,
        color: [
          [lineColor, lineColor, lineColor, lineColorPrimary][
            Math.round(Math.random() * 3)
          ],
          [lineColor, lineColor, lineColor, lineColorPrimary][
            Math.round(Math.random() * 3)
          ],
        ],
      }));

      const ringsData = MAP_AIRPORTS.map(({ lat, lng }, index) => ({
        lat,
        lng,
        repeatPeriod: Math.floor(Math.random() * (3000 - 1600 + 1) + 1600),
      }));

      const MAP_CENTER = { lat: 22.2, lng: 22.2 };
      const globe = window.Globe({
        animateIn: false,
      });
      const urlPrefix = `${this.$config.IMAGEKIT}/${this.$config.productionUrl}`;
      globe(this.$el)
        .globeImageUrl(this.isDark ? `${urlPrefix}/img/globe-dark.png` : `${urlPrefix}/img/globe-light.png`)
        // arcs
        .arcsData(arcsData)
        .arcColor('color')
        .arcDashLength(1)
        .arcStroke(0.3)
        .arcDashAnimateTime(2000)
        .arcDashGap(() => Math.random())
        .arcsTransitionDuration(0)

        // rings
        .ringsData(ringsData)
        .ringColor(() => (t) => this.isDark ? `rgba(255,255,255,${1 - t})` : `rgba(0,0,0,${1 - t})`)
        .ringMaxRadius(2)
        .ringPropagationSpeed(5)
        .ringRepeatPeriod('repeatPeriod')

        // settings
        .atmosphereColor(this.isDark ? '#414141' : '#9D9D9D')
        .atmosphereAltitude(0.09)
        .backgroundColor('rgba(0,0,0,0)')
        .pointOfView({ ...MAP_CENTER, altitude: 10 }, 1)
        .width(globeSize)
        .height(globeSize);

      globe.controls().enableZoom = false;
      globe.controls().autoRotate = true;
      globe.controls().autoRotateSpeed = 0.8;

      globe.pointOfView({ ...MAP_CENTER, altitude: 2.1 }, 2000);
    },
    getNext(list, index) {
      const isLast = index === list.length - 1;
      return list[isLast ? 0 : index + 1];
    },
  },
};

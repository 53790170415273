// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/icon-check-filled.svg?data");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StickyTop[data-v-7f555689]{top:18vh}@media (min-height:980px){.StickyTop[data-v-7f555689]{top:28vh}}.Content[data-v-7f555689] ul{margin-bottom:.75rem}.Content[data-v-7f555689] ul li{margin-bottom:1rem;margin-top:1rem;padding-left:2.25rem;position:relative}.Content[data-v-7f555689] ul li:last-child{margin-bottom:0}.Content[data-v-7f555689] ul li:before{--tw-bg-opacity:1;--tw-content:\"\";background-color:#8bcf2b;background-color:rgb(139 207 43/var(--tw-bg-opacity));content:\"\";content:var(--tw-content);display:block;height:1.25rem;left:0;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-size:100% 100%;mask-size:100% 100%;position:absolute;top:1px;width:1.25rem}.Content[data-v-7f555689] ul+p{margin-left:2.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

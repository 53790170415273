
import ImageBgGrid from '~/assets/img/image-bg-grid.svg?inline';

export default {
  components: {
    ImageBgGrid,
  },

  data() {
    return {
      items: ['A', 'B', 'C'],
      inViewportItem: 'A',
      isSwitched: true,
    };
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    async handleScroll() {
      let inViewportItem = 'A';

      (this.$refs.Content || []).forEach((item) => {
        const isInViewport = this.$refs.Icon.getBoundingClientRect().top > item.getBoundingClientRect().top;
        if (isInViewport) {
          inViewportItem = item.getAttribute('data-item');
        }
      });

      if (this.inViewportItem !== inViewportItem) {
        this.isSwitched = false;
        await this.$sleep(300);
        this.inViewportItem = inViewportItem;
        this.isSwitched = true;
      }
    },
  },
};


export default {
  props: {
    state: {
      type: String,
      default: null,
    },
    except: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      funds: [],
    };
  },

  async fetch() {
    try {
      this.funds = await this.$axios.$get('/public/funds', {
        params: {
          state: this.state,
          except: this.except,
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
};
